<template>
  <!-- Set component styling -->
  <component :is="`style`">
    <!-- Api driven styles -->
    html{
      background: transparent !important;
    }
    .event-emergency-frame{
      width: 100vw;
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(255,255,255,.7);
      padding: 10px;
    }
    .event-emergency-frame h1{
      font-weight: bold;
      color: #333;
      text-align: center;
    }
  </component>
  <main class="event-emergency-frame" v-if="event?.status === 'active' && event?.emergency !== '' ">
    <div class="event-emergency">
      <h1>{{event?.emergency}}</h1>
    </div>
  </main>
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/database';

// Third party imports
import { VuemojiPicker } from 'vuemoji-picker';

export default {
  name: 'event',
  components: {
    VuemojiPicker,
  },
  data() {
    return {
      id: '',
      uuid: '',
      // No event
      noEvent: false,
      // Event preview
      showEventPreview: false,
      // Client Information
      clientKey: '',
      clientEmergencyMessage: '',
      // colors
      background: '',
      tabBackground: '',
      tabFontColor: '',
      tabActiveBackground: '',
      tabActiveFontColor: '',
      buttonBackground: '',
      buttonTextColor: '',
      buttonActiveBackgroundColor: '',
      buttonActiveFontColor: '',
      commentFontColor: '',
      commentBackgroundColor: '',
      // Fonts
      fontSize: '',
      fontURL: '',
      primaryFont: '',
      secondaryFont: '',
      // Event
      event: {},
      // Sessions
      activeSessions: false,
      sessions: [],
      // Counters
      selectedKey: '',
      sessionsToWatch: [],
      counters: [],
      updatedKey: '',
      missedMessageCountChat: 0,
      missedMessageCountInit: false,
      missedMessageCountContent: 0,
      missedMessageCountContentInit: false,
      missedMessageContentActiveAmount: 0,
      missedMessageActiveTab: 0,
      // Selected session
      sessionSelected: false,
      viewingThisSession: {},
      viewingThisSessionKey: '',
      // Session flags
      showSessionInformation: false,
      protected: false,
      protectedButton: false,
      anonymous: false,
      register: false,
      notLoggedIn: false,
      // Pinned message
      hasPinnedMessage: false,
      pinnedMessage: {},
      isPinnedURL: false,
      // Session comments
      sessionComment: {
        key: '',
        uid: '',
        name: '',
        email: '',
        comment: '',
        status: '',
        date: '',
        isNew: true,
        nameError: false,
        emailError: false,
        commentError: false,
      },
      // Emoji
      selectingEmoji: false,
      // Fields
      showFields: false,
      showUserInfo: false,
      // Prevention states
      preventionState: false,
      submittingError: false,
      // submitting data
      typeToSubmit: '',
      submitting: false,
      // Scrolling
      scrollToBottom: true,
      initialScrollHeight: 0,
    };
  },
  created() {
    // Grab the event id from the url
    // TODO: Note this may change if you update the url
    // eslint-disable-next-line prefer-destructuring
    this.id = window.location.pathname.split('/')[2];
    if (this.id !== undefined && this.id !== null) {
      this.getEvent();
      this.genUUID();
      this.getFonts();
    }
  },
  mounted() {
    const vm = this;
    setTimeout(() => {
      vm.missedMessageCountChat = 0;
      vm.missedMessageCountInit = true;
      vm.missedMessageCountContent = vm.missedMessageContentActiveAmount;
      vm.missedMessageCountContentInit = true;
    }, 1500);
  },
  methods: {
    getEvent() {
      const eventRef = firebase.database().ref(`events/${this.id}`);
      eventRef.on('value', (snapshot) => {
        if (snapshot.exists()) {
          const eventObj = snapshot.val();
          this.setMetaData(eventObj.favicon, eventObj.name);
          this.setEventStyling(eventObj);
          if (eventObj?.status === 'inactive' || eventObj?.status === 'completed') {
            // Run a check for the admin for the ability to preview the event event
            if (this.$route.query.firstname && this.$route.query.emailaddress) {
              // Let's check for admin
              if (this.$route.query.firstname === 'Admin' && this.$route.query.emailaddress === 'Admin@go.huddlearea.com') {
                console.info(`Event is ${eventObj?.status}, and admin is previewing it.`);
                this.showEventPreview = true;
                if (eventObj?.sessions) {
                  eventObj.sessions = Object.values(eventObj?.sessions);
                  this.getSessions(Object.values(eventObj?.sessions));
                } else {
                  eventObj.sessions = [];
                }
              } else {
                console.info(`Event is ${eventObj?.status}, and no admin is previewing it.`);
                this.showEventPreview = false;
              }
            } else {
              console.info(`Event is ${eventObj?.status}, and general public is previewing it.`);
              this.showEventPreview = false;
            }
          }
          // Get sessions only when event is active -- no need to waste resources
          if (eventObj?.status === 'active') {
            // Check if we have sessions
            if (eventObj?.sessions) {
              eventObj.sessions = Object.values(eventObj?.sessions);
              this.getSessions(Object.values(eventObj?.sessions));
            } else {
              eventObj.sessions = [];
            }
          }
          this.event = eventObj;
        } else {
          console.info('No event per this id');
          this.noEvent = true;
        }
      });
    },
    // Getting sessions and setting defualt session
    getSessions(sessions) {
      const vm = this;
      try {
        /**
         * id 0: Live chat
         * id 1: QA
         * id 2: Dynamic media
         */
        // Order by oderID
        if (sessions && sessions.length >= 2) {
          sessions.sort((a, b) => a.orderID - b.orderID);
        }
        sessions.forEach((session) => {
          const sessionRef = firebase.database().ref(`sessions/${session.sessionID}`);
          sessionRef.on('value', (snap) => {
            if (snap.exists()) {
              const sessionObj = snap.val();
              if (sessionObj.status === 'active') {
                // Check to see if we add this to the array or if it already exists in that array
                const search = this.sessions.find((element) => element.sessionID === session.sessionID);
                if (search !== undefined) {
                  // eslint-disable-next-line no-shadow
                  const indexSearch = this.sessions.findIndex(((obj) => obj.sessionID === session.sessionID));
                  this.sessions[indexSearch] = sessionObj;
                } else {
                  this.sessions.push(sessionObj);
                }
              } else {
                // eslint-disable-next-line no-unused-expressions
                // eslint-disable-next-line no-shadow
                this.sessions = this.sessions.filter((session) => session.sessionID !== sessionObj.sessionID);
              }
              if (this.sessions && this.sessions.length >= 1) {
                // eslint-disable-next-line no-shadow
                const activeArr = this.sessions.filter((session) => session.status === 'active');
                if (activeArr.length >= 1) {
                  this.activeSessions = true;
                } else {
                  this.activeSessions = false;
                }
              } else {
                this.activeSessions = false;
              }
            }
          });
        });
        sessions.forEach((session) => {
          if (session.type === 'chat') {
            const ref = firebase.database().ref(`sessions/${session.sessionID}`);
            const comments = ref.child('comments');
            comments.on('child_added', (dataSnapshot) => {
              const commentChanged = dataSnapshot.val();
              if (commentChanged.status === 'approved') {
                this.updateCounters(session.sessionID);
                vm.missedMessageCountChat += 1;
              }
            });
            comments.on('child_changed', (dataSnapshot) => {
              const commentChanged = dataSnapshot.val();
              if (commentChanged.status === 'approved') {
                this.updateCounters(session.sessionID);
              }
            });
            ref.on('value', (snap) => {
              const obj = snap.val();
              let tempCount = 0;
              // // eslint-disable-next-line no-shadow
              if (obj.comments) {
                const contents = Object.entries(obj.comments);
                contents.forEach((c) => {
                  if (c[1].status === 'approved') {
                    // eslint-disable-next-line no-plusplus
                    tempCount++;
                  }
                });
                if (tempCount === 0) {
                  this.resetCounter(session.sessionID);
                }
                this.scrolling();
              }
            });
          }
          if (session.type === 'content') {
            const ref = firebase.database().ref(`sessions/${session.sessionID}`);
            const comments = ref.child('contents');
            comments.on('child_added', (dataSnapshot) => {
              const commentChanged = dataSnapshot.val();
              if (commentChanged.status === 'approved') {
                this.updateCounters(session.sessionID);
              }
            });
            comments.on('child_changed', (dataSnapshot) => {
              const commentChanged = dataSnapshot.val();
              if (commentChanged.status === 'approved') {
                this.updateCounters(session.sessionID);
              }
            });
            ref.on('value', (snap) => {
              const obj = snap.val();
              let tempCount = 0;
              // eslint-disable-next-line no-shadow
              const contents = Object.entries(obj.contents);
              vm.missedMessageContentActiveAmount = 0;
              contents.forEach((c) => {
                if (c[1].status === 'approved') {
                  vm.missedMessageContentActiveAmount += 1;
                  // eslint-disable-next-line no-plusplus
                  tempCount++;
                }
              });
              if (tempCount === 0) {
                this.resetCounter(session.sessionID);
              }
            });
          }
        });
        this.createCounters();
        this.getSession(sessions[0]);
      } catch (e) {
        if (e) {
          console.error(`Problem getting sessions ${e}`);
        }
      }
    },
    // Helper functions
    // Check for active amount
    /**
     * Generate a unique uid per client connection
     */
    genUUID() {
      // Check for localstorage first
      const uidCheck = sessionStorage.getItem('uid');
      if (uidCheck !== undefined && uidCheck !== null) {
        this.uuid = uidCheck;
      } else {
        const uid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
          // eslint-disable-next-line no-bitwise,no-mixed-operators
          const r = Math.random() * 16 | 0; const v = c === 'x' ? r : (r & 0x3 | 0x8);
          return v.toString(16);
        });
        this.uuid = uid;
        sessionStorage.setItem('uid', this.uuid);
      }
    },
    /**
     * Generate meta information from the event if set
     * faviconURL: This is the favicon url
     * eventName: Is the name of the event to update the browser tab
     */
    setMetaData(faviconURL, eventName) {
      document.title = eventName;
      // TODO: redo the favicon logic
    },
    /**
     * Set the event colors set on the event obj stored in the firebase side of things.
     */
    setEventStyling(data) {
      // font size
      this.fontSize = data.fontSize;
      // Color settings
      this.background = data.backgroundColor;
      this.tabFontColor = data.tabFontColor;
      this.tabBackground = data.tabBackground;
      this.tabActiveBackground = data.tabActiveBackground;
      this.tabActiveFontColor = data.tabActiveFontColor;
      this.buttonBackground = data.buttonBackground;
      this.buttonTextColor = data.buttonTextColor;
      this.buttonActiveBackgroundColor = data.buttonActiveBackgroundColor;
      this.buttonActiveFontColor = data.buttonActiveFontColor;
      this.commentFontColor = data.chatTextBackground;
      this.commentBackgroundColor = data.chatMessageBackground;
    },
    /**
     * Set the event fonts deilivered from the wordpress side of things.
     * Two params passed in: gofont, gotabfont, gomessagefont
     * gofont: Main font
     * gotabfont: font related to tab fonts
     * gomessagefont: font located in the messages section
     */
    getFonts() {
      if (this.$route.query.gofont !== undefined) {
        const fontURL = atob(this.$route.query.gofont);
        const primaryFont = atob(this.$route.query.gotabfont).replace('_', ' ');
        const secondaryFont = atob(this.$route.query.gomessagefont).replace('_', ' ');
        const fontLink = document.createElement('link');
        fontLink.rel = 'stylesheet';
        fontLink.href = fontURL;
        document.getElementsByTagName('head')[0].appendChild(fontLink);
        this.primaryFont = primaryFont;
        this.secondaryFont = secondaryFont;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  ::-webkit-scrollbar {
    display: none !important;
    width: 0 !important;
    /*FireFox*/
    scrollbar-width: none !important;
    /*IE10+*/
    -ms-overflow-style: -ms-autohiding-scrollbar !important;
  }
  .alert{
    background-color: rgba(255,255,255,.7);
    padding: 20px;
    margin: 10px;

    p{
      font-size: 14px;
      font-style: italic;
      &:not(:first-child) {
        padding-top: 5px;
      }
    }
  }
  .event-frame {
    width: 100%;
    min-height: 120vh;
    height: 120vh;
    overflow: hidden;
    min-height: -webkit-fill-available;

    padding: 0;
    margin: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;

    background: #ffffff;

    .event {
      width: 100%;
      max-width: 1400px;
      min-height: 120vh;
      height: 120vh;
      min-height: -webkit-fill-available;

      padding: 0;
      margin: 0 auto;

      position:absolute;
      top:0;
      left:0;
      right:0;
      bottom:0;

      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      @media screen and (max-width: 15rem) {
        background: none !important;
      }

      .tabs {
        width: 100%;
        height: 40px;
        min-height: 40px;
        max-height: 41px;
        overflow-y: scroll;

        padding: 0;
        margin: 0;

        @media screen and (max-width: 15rem) {
          max-height: 100vh;
          height: 100%;
          overflow: hidden;
          width: 35px;
          display:flex;
          flex-direction: column;
          justify-content: center;
          -webkit-writing-mode: vertical-rl;
          writing-mode: vertical-rl;
          writing-mode: tb-rl;
          text-align: left;
          margin-top: -4px;
          border: none !important;
          margin-top: 0px;
          background: transparent;
          transform: rotate(180deg);
        }

        ul {
          border-bottom: none transparent;

          @media screen and (max-width: 15rem) {
            justify-content: center !important;
            margin-left: 4px;
          }

          li {
            width: fit-content;
            border: none transparent;
            color: var(--accent-color);
            font-weight: bold;

            justify-content: space-between;
            padding: 10px 15px 10px 15px;
            align-items: center;

            display: flex;
            flex-grow: 1;

            &:not(:last-child) {
              margin-right: 3px;

              @media screen and (max-width: 15rem) {
                &:not(:last-child) {
                  border-bottom: 1px solid black !important;
                }
              }
            }

            cursor: pointer;

            @media screen and (max-width: 15rem) {
              padding: 30px 6px 30px 6px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-top-left-radius: 0;
              border-top-right-radius: 0;
              margin-right: 0 !important;
              flex-grow: 0 !important;
            }
            @media screen and (max-width: 5rem) {
              margin-right: 0 !important;
              justify-content: center;
            }

            .counter {
              width: 15px;
              height: 15px;
              background: white;
              border-radius: 50%;
              display: flex;
              align-items: center;
              align-content: center;
              justify-content: center;
              padding: 0;
              font-size: 0.8rem;

              @media screen and (max-width: 15rem) {
                margin-top: 10px;
                margin-right: 3px;
              }
            }
            .has-icon {
              @media screen and (max-width: 15rem) {
                transform: rotate(90deg);
              }
            }

            .hidden {
              display: none !important;
              width: 0 !important;
              height: 0 !important;
            }
          }
        }
      }
      .session {
        width: 100%;
        height: 100%;
        max-height: calc(100% - 40px);

        @media screen and (max-width: 15rem) {
          min-height: 100vh;
          max-height: 100vh;
          display: none !important;
        }

        .session-contents {
          width: 100%;
          min-height: 94vh;
          max-height: 94vh;
          overflow: hidden;

          padding: 0;
          margin: 0;

          display: flex;
          flex-direction: column;

          .comments-section {
            width: 100%;
            max-height: 77vh;
            //overflow: hidden;
            overflow: scroll;
            flex-grow: 1;
            padding: 10px;

            .pinned-message {
              width: 100%;
              height: auto;

              padding: 10px 0 10px 0;
              margin-bottom: 13px;

              border-bottom: 1px solid #333;

              display: flex;
              flex-direction: row;
              align-items: center;
              align-content: center;
              justify-content: flex-start;
              background: transparent;

              p {
                word-break: break-word;
              }
            }

            ul {
              width: 100%;
              max-height: 77vh;
              overflow: scroll;

              &::-webkit-scrollbar {
                display: none;
              }
            }

            .pinnedStyling {
              overflow: scroll;
              max-height: 64vh !important;
              padding-bottom: 10px !important;
            }

            img {
              height: auto;
              max-height: fit-content;
            }

            .comment {
              width: 100%;
              height: auto;
              margin-top: 10px;

              display: flex;
              flex-direction: column;
              align-items: flex-start;
              align-content: center;
              justify-content: flex-start;
              background: transparent;

              .comment-name {
                width: 100%;
                height: auto;

                color: hsl(0, 0%, 20%);
                font: 600 12px "Helvetica Neue", sans-serif;
                text-transform: capitalize;
                padding: 0 0 1px 0;
                margin-bottom: 2px;
                display: flex;
                align-items: center;
                align-content: center;
              }

              .comment-content {
                width: 100%;
                max-width: 99%;
                height: auto;

                font-size: .975rem;
                letter-spacing: 0.05px;
                line-height: 1.65;
                word-wrap: break-word;
                color: #333333;

                float: left;
                display: inline-block;
                position: relative;

                border-bottom: 1px solid lightgray;
                padding-bottom: 7px;
              }
            }

            @supports (-webkit-touch-callout: none) {
              min-height: 45%;
              max-height: 45%;
            }
            @media screen and (max-width: 45rem) {
              max-height: none;
            }
          }
          .border-top {
            border-top: 1px solid;
          }
          .border-bottom {
            border-bottom: 1px solid;
          }
          .required-fields {
            width: 100%;
            height: fit-content;

            padding: 10px 10px 20px 10px;

            @supports (-webkit-touch-callout: none) {
              flex-grow: 1;
            }

            @media screen and (max-width: 45rem) {
              flex-grow: 0;
            }

            textarea, .textarea {
              resize: none;

              @media screen and (max-width: 45rem) {
                width: 100%;
                min-height: 0;
                height: 50px;
                max-height: 50px;
              }
            }

            .bottom-section {
              width: 95%;
              margin-top: 10px;
            }

            .button {
              height: fit-content !important;
              color: #333;
              padding: 2px 10px 2px 10px;
              font-weight: 700;
              font-size: 1.1rem !important;
            }
          }
          .smaller {
            @supports (-webkit-touch-callout: none) {
              max-height: 60% !important;
              min-height: 40% !important;
            }
            @media screen and (max-width: 45rem) {
              min-height: 59%;
            }
          }
          .locked-message {
            width: 100%;
            height: auto;

            padding: 20px;

            display: flex;
            align-items: center;
            align-content: center;
            justify-content: center;
            font-weight: 600;
            font-size: 1.2rem;
          }
          // File downloads
          .fileDownload {
            width: 100%;
            height: auto;

            background: #F5F7FB;
            border: 1px solid lighten(#5F5DFF, 22);

            a {
              width: 100%;
              height: 100%;

              padding: 20px;

              display: flex;
              flex-direction: row;
              align-items: center;
              align-content: center;
              justify-content: center;

              &:hover, &:active {
                background: darken(#F5F7FB, 3);
              }
            }
          }
        }
        .session-contents-empty {
          width: 100%;
          height: 100vh;

          align-items: center;
          align-content: center;
          justify-content: center !important;
          font-weight: bold;
          font-size: 1.3rem;
        }
      }
    }
  }
  @media screen and (min-width: 46rem) {
    .emoji-section {
      max-width: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
    }
  }
  @media screen and (max-width: 45rem) {
    .emoji-section {
      width: 95%;
      margin: 0 auto;
    }
  }
</style>
